export type Spec = {
  propertyId: string;
  field: string;
};

export const FALLBACK_LANGUAGE = 'en';
export const LANGUAGE_SELECTION = {
  fr: 'Français',
  nl: 'Nederlands',
  en: 'English'
};

export const BREAKPOINTS = {
  // Same as antd so it corresponds with the responsive grid system: https://ant.design/components/grid/
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600
};

export const APP_NAME = 'hyper leasing';

// Calculator initial values
// Vehicle details
export const CALCULATOR_DEFAULT_PURCHASE_PRICE = 25000;

// Leasing
export const CALCULATOR_DEFAULT_SALES_PRICE = 25000;
export const CALCULATOR_DEFAULT_STARTUP_FEE_PERCENTAGE = 0;
export const CALCULATOR_DEFAULT_ADMINISTRATION_FEE = 0;
export const CALCULATOR_DEFAULT_DEPOSIT = 4000;
export const CALCULATOR_DEFAULT_RESIDUAL_VALUE = 750;
export const CALCULATOR_DEFAULT_INTEREST_PERCENTAGE = 20;
export const CALCULATOR_DEFAULT_MARGIN_PERCENTAGE = 25;
export const CALCULATOR_DEFAULT_DURATION_MONTHS = 72;
export const CALCULATOR_DURATION_IN_MONTHS_SLIDER_STEP = 2;
export const CALCULATOR_DURATION_IN_MONTHS_SLIDER_STEP_ADMINS = 1;
export const CALCULATOR_DURATION_IN_MONTHS_SLIDER_MIN = 6;
export const CALCULATOR_DURATION_IN_MONTHS_SLIDER_MAX = 72;
export const CALCULATOR_DURATION_IN_MONTHS_SLIDER_MAX_ADMINS = 100;

// Bank
export const CALCULATOR_DEFAULT_BANK_DURATION_IN_MONTHS = 72;
export const CALCULATOR_BANK_DURATION_IN_MONTHS_SLIDER_STEP = 6;
export const CALCULATOR_BANK_DURATION_IN_MONTHS_SLIDER_MIN = 6;
export const CALCULATOR_BANK_DURATION_IN_MONTHS_SLIDER_MAX = 72;
export const CALCULATOR_DEFAULT_BANK_RESIDUAL_VALUE = 750;
export const CALCULATOR_DEFAULT_BANK_DEPOSIT = 4000;
export const CALCULATOR_DEFAULT_BANK_INTEREST_PERCENTAGE = 4;

// Insurance
export const CALCULATOR_DEFAULT_INSURANCE_AMOUNT = 25000;
export const CALCULATOR_DEFAULT_INCLUDES_CIVIL_LIABILITY = false;
export const CALCULATOR_DEFAULT_INCLUDES_SPECIAL_INSURANCE = false;
export const CALCULATOR_DEFAULT_INCLUDES_ALL_RISKS = false;
export const CALCULATOR_DEFAULT_INCLUDES_YEARLY_ROAD_TAX = false;
export const CALCULATOR_DEFAULT_INCLUDES_ONE_TIME_ROAD_TAX = false;
